<template>
  <div class="home">
  <TksTb/>
  </div>
</template>

<script>
import TksTb from '@/components/TksTb.vue';

export default {
  name: 'ThanKs',
  components: {
      TksTb
  }
}
</script>
<style>
body {background-color:#e8e8e8!important;}
</style>