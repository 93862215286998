<template>
  <div class="home">
    <HoMe />
  </div>
</template>

<script>
// @ is an alias to /src
import HoMe from '@/views/Home.vue'

export default {
  name: 'HomeView',
  components: {
    HoMe
  }
}
</script>
